<template>
    <el-row :gutter="10" align="middle" type="flex" justify="center">
        <el-col :span="8">
            <div style="margin-bottom: 20px">{{ types[type] }} {{ number }}</div>
        </el-col>
        <el-col>
            <el-form-item
                :prop="`parcels[${index}].length`"
                :rules="[
                    { required: true, message: 'Lungimea este obligatorie', trigger: 'blur' },
                    { type: 'number', message: 'Lungimea trebuie sa fie un numar' },
                    {
                        validator: validateMax,
                        message: 'Lungimea trebuie sa fie mai mica de 300 cm',
                        v: 300,
                        trigger: 'blur',
                    },
                    {
                        validator: validateMin,
                        message: 'Lungimea trebuie sa fie mai mare de 0 cm',
                        v: 0.01,
                        trigger: 'blur',
                    },
                ]"
            >
                <el-input v-model.number="length">
                    <template slot="prepend">Lungime</template>
                    <template slot="append">cm</template>
                </el-input>
            </el-form-item>
        </el-col>
        <el-col>
            <el-form-item
                :prop="`parcels[${index}].width`"
                :rules="[
                    { required: true, message: 'Latimea este obligatorie', trigger: 'blur' },
                    { type: 'number', message: 'Latimea trebuie sa fie un numar' },
                    {
                        validator: validateMax,
                        message: 'Latimea trebuie sa fie mai mica de 300 cm',
                        v: 300,
                        trigger: 'blur',
                    },
                    {
                        validator: validateMin,
                        message: 'Latimea trebuie sa fie mai mare de 0 cm',
                        v: 0.01,
                        trigger: 'blur',
                    },
                ]"
            >
                <el-input v-model.number="width">
                    <template slot="prepend">Latime</template>
                    <template slot="append">cm</template>
                </el-input>
            </el-form-item>
        </el-col>
        <el-col>
            <el-form-item
                :prop="`parcels[${index}].height`"
                :rules="[
                    { required: true, message: 'Inaltimea este obligatorie', trigger: 'blur' },
                    { type: 'number', message: 'Inaltimea trebuie sa fie un numar' },
                    {
                        validator: validateMax,
                        message: 'Inaltimea trebuie sa fie mai mica de 300 cm',
                        v: 300,
                        trigger: 'blur',
                    },
                    {
                        validator: validateMin,
                        message: 'Inaltimea trebuie sa fie mai mare de 0 cm',
                        v: 0.01,
                        trigger: 'blur',
                    },
                ]"
            >
                <el-input v-model.number="height">
                    <template slot="prepend">Inaltime</template>
                    <template slot="append">cm</template>
                </el-input>
            </el-form-item>
        </el-col>
        <el-col>
            <el-form-item
                :prop="`parcels[${index}].weight`"
                :rules="[
                    { required: true, message: 'Greutatea este obligatorie', trigger: 'blur' },
                    { type: 'number', message: 'Greutatea trebuie sa fie un numar' },
                    {
                        validator: validateMax,
                        message: 'Greutatea trebuie sa fie mai mica de 300 kg',
                        v: 300,
                        trigger: 'blur',
                    },
                    {
                        validator: validateMin,
                        message: 'Greutatea trebuie sa fie mai mare de 0 kg',
                        v: 0.01,
                        trigger: 'blur',
                    },
                ]"
            >
                <el-input v-model.number="weight">
                    <template slot="prepend">Greutate</template>
                    <template slot="append">kg</template>
                </el-input>
            </el-form-item>
        </el-col>
    </el-row>
</template>

<script>
import { sync, call, get } from 'vuex-pathify';

export default {
    props: {
        index: {
            required: true,
        },
    },
    data() {
        return {
            types: {
                0: 'Plic',
                1: 'Pachet',
            },
        };
    },
    methods: {
        validateMax(rule, value, callback) {
            if (value > rule.v) {
                callback(new Error(rule.message));
            } else {
                callback();
            }
        },
        validateMin(rule, value, callback) {
            if (value < rule.v) {
                callback(new Error(rule.message));
            } else {
                callback();
            }
        },
    },
    computed: {
        ...sync('sales/order@awb.form.parcels[:index]', {
            type: 'type',
            length: 'length',
            width: 'width',
            height: 'height',
            weight: 'weight',
            number: 'number',
        }),
    },
};
</script>
